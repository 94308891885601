<div #scrollHome class="page-home">
  @if(currentScreenSize === 'xSmall'){
  <app-home-mobile></app-home-mobile>
  }@if(currentScreenSize === 'small' || currentScreenSize === 'customTablet'){
  <app-home-tablet></app-home-tablet>
  }@if(currentScreenSize === 'desktop'){
  <!-- <app-header activeMenu="home" /> -->
   <app-header-simple activeMenu="home"></app-header-simple>
  <div class="d-flex container-xxl m-auto home-top">
    @if(!islogin){
      <app-login-home class="d-none d-md-block"></app-login-home>
    }
    <app-slideshow-home [ngStyle]="{'width': islogin ?'100%':'calc(100% - 360px)'}" [data]="slideshowJSONData" [isLogin]="islogin" />
  </div>
  <div class="row home-middle container-xxl m-auto">
    <div class="p-0" [ngClass]="hideLiveScore ? 'col-md-12': 'col-md-9'">
      <app-jackpot [value]="5000000" />
      @if(!hideLiveScore){
        <div class="d-flex justify-content-between mt-4">
          <div class="home-run-bet d-none d-lg-block">
            <div
              class="d-flex align-items-center justify-content-between home-run-bet-header"
            >
              <p class="title-bet">RUNNING BETS</p>
              <p class="total-bet">12</p>
            </div>
            <div
              class="d-flex align-items-center justify-content-between home-run-bet-body"
            >
              <img
                src="../../../template/green/assets/images/SBOBET_Logo.png"
                width="82"
                height="20"
              />
              @for (item of betList; track item;) {
              <div
                class="d-flex align-items-center justify-content-between bet-detail w-100"
              >
                <div class="bet-detail-info">
                  <span class="d-flex align-items-center">
                    <p class="bet-info-team">{{ item.team1 }}</p>
                    <span class="bet-info-vs">vs</span>
                    <p class="bet-info-team">{{ item.team2 }}</p>
                  </span>
                  <p class="bet-info-win">Bets {{ item.winner }} WINS</p>
                </div>
                <p class="bet-info-number">
                  Rp {{ item.totalBet | formatNumberId }}
                </p>
              </div>
              }
            </div>
            <div
              class="d-flex align-items-center justify-content-between home-run-bet-body"
            >
              <img
                src="../../../template/green/assets/images/saba_sport.png"
                width="53"
                height="20"
                class="mb-2"
              />
              @for (item of sabaBetList; track item;) {
              <div
                class="d-flex align-items-center justify-content-between bet-detail w-100"
              >
                <div class="bet-detail-info">
                  <span class="d-flex align-items-center">
                    <p class="bet-info-team">{{ item.team1 }}</p>
                    <span class="bet-info-vs">vs</span>
                    <p class="bet-info-team">{{ item.team2 }}</p>
                  </span>
                  <p class="bet-info-win">Bets {{ item.winner }} WINS</p>
                </div>
                <p class="bet-info-number">
                  Rp {{ item.totalBet | formatNumberId }}
                </p>
              </div>
              }
            </div>
          </div>
          <div class="home-other-match">
            <div class="d-flex flex-column home-other-match-header">
              <div class="d-flex align-items-center justify-content-between">
                <p class="title-bet">OTHER MATCHES</p>
                <p class="title-bet">
                  <a href="#">
                    SEE ALL <fa-icon [icon]="faAngleRight"> </fa-icon>
                  </a>
                </p>
              </div>
              <div class="latest-news">
                <div class="latest-news-left">
                  <img
                    src="../template/green/assets/images/ic-bullhorn.png"
                    width="12"
                    height="10"
                  />
                </div>
                <div class="latest-news-right">
                  <div class="latest-news-right-inner">
                    <span class="newsticker-item"
                      >Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure Latin
                      words, consectetur, from a Lorem Ipsum passage, and going
                      through the cites of the word in classical literature,
                      discovered the undoubtable source. Lorem Ipsum comes from
                      sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                      Malorum" (The Extremes of Good and Evil) by Cicero, written
                      in 45 BC. This book is a treatise on the theory of ethics,
                      very popular during the Renaissance. The first line of Lorem
                      Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
                      section 1.10.32.</span
                    >
                    <span class="marquee-sep"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column home-other-match-body mt-2 w-100">
              @for (item of otherMatchData; track item;) {
              <div
                class="detail-other-match"
                [class.cardMatchEven]="($index + 1) % 2 === 0"
              >
                <div class="is-live-info">
                  @if(item.live === true && item.phase != 9){
                  <div class="d-flex align-items-center text-white">
                    <img
                      src="../../../template/green/assets/images/Live.png"
                      width="41"
                      height="16"
                      class="is-live"
                    />
                    <img
                      #shakeImage
                      src="../template/green/assets/images/ic-timer.png"
                      width="11"
                      height="12"
                    />
                    @if(item.phase == 7){
                    <span class="min-live">Penalty</span>
                    } @else if(item.phase == 9){
                    <span class="min-live">Fulltime</span>
                    } @else if(item.phase == 3 || item.phase == 5){
                    <span class="min-live">Halftime</span>
                    }@else {
                    <span #shakeSpan class="min-live"
                      >{{ getMinuteDifference(item.match_start_at) }}’</span
                    >
                    }
                  </div>
                  } @if(!item.live && item.phase == 9){
                  <div>
                    <p class="info-time">
                      {{ item.match_start_at | formatTimeId }}
                    </p>
                    <p class="info-date">
                      {{ item.match_start_at | formatDateId }}
                    </p>
                  </div>
                  }
                </div>
                <div
                  class="d-flex align-items-center justify-content-center text-white other-middle-info"
                >
                  <div class="d-flex team-info team-info-left align-items-center">
                    <p class="team-score mb-0 mx-1">{{ item.home_team_name }}</p>
                    <img [src]="item.home_team_image" width="25" height="25" />
                  </div>
                  <p class="team-score mx-3">
                    @if(!item.live){ VS } @else {
                    {{ item.home_score }} : {{ item.away_score }}
                    }
                  </p>
                  <div
                    class="d-flex team-info team-info-right align-items-center"
                  >
                    <img [src]="item.away_team_image" width="25" height="25" />
                    <p class="team-score mb-0 mx-1">{{ item.away_team_name }}</p>
                  </div>
                </div>
                <div class="btn-place-bet">
                  <button class="place-bet">Place</button>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      }@else{
        <div class="list-promotion-container-hide-livescrore">
          <div class="list-promotion">
            @if(promoJSONData.length > 0) {
            <swiper-container init="false" #swiperContainer class="swiper-container">
              @for (item of promoJSONData; track item;) {
              <swiper-slide class="swiper-item">
        
                @if(item.thumbnail_url !== '') {
                <a [routerLink]="['/promotion']" [queryParams]="{ part: item._id }" class="w-100">
                  <img [src]="item.thumbnail_url" [alt]="item.title" class="w-100" priority />
                </a>
                }
              </swiper-slide>
              }
            </swiper-container>
            <a href="/promotion" class="w-100 see-all-promo">See All Promo</a>
            }
          </div>
        </div>
      }
    </div>
    @if(!hideLiveScore){
      <div class="col-md-3 pe-0 home-middle-right">
        <div class="big-match">
          <div class="big-match-header">BIG MATCH</div>
          <div class="big-match-body">
            <p class="title">{{ bigMatchData.league_name }}</p>
            <div class="d-flex align-items-center justify-space-between">
              <div>
                <img
                  [src]="bigMatchData.home_team_image"
                  width="92"
                  height="92"
                />
                <p class="big-match-team-name">
                  {{ bigMatchData.home_team_name }}
                </p>
              </div>
              <div class="w-25 d-flex flex-column align-items-center mx-2">
                <span class="d-flex align-items-center">
                  @if(!bigMatchData.live){ VS } @else {
                  <p
                    class="big-match-score"
                    [ngStyle]="{
                      opacity: getOpacity(
                        bigMatchData.home_score,
                        bigMatchData.away_score,
                        'home'
                      )
                    }"
                  >
                    {{ bigMatchData.home_score }}
                  </p>
                  <p class="big-match-score mx-2">:</p>
                  <p
                    class="big-match-score"
                    [ngStyle]="{
                      opacity: getOpacity(
                        bigMatchData.home_score,
                        bigMatchData.away_score,
                        'away'
                      )
                    }"
                  >
                    {{ bigMatchData.away_score }}
                  </p>
                  }
                </span>
  
                <div class="d-flex align-items-center text-white my-2">
                  <img
                    src="../template/green/assets/images/ic-timer.png"
                    width="11"
                    height="12"
                  />
                  @if(bigMatchData.phase == 7){
                  <span class="min-live">Penalty</span>
                  } @else if(bigMatchData.phase == 9){
                  <span class="min-live">Fulltime</span>
                  } @else if(bigMatchData.phase == 3 || bigMatchData.phase == 5){
                  <span class="min-live">Halftime</span>
                  }@else {
                  <span #shakeSpan class="min-live"
                    >{{ getMinuteDifference(bigMatchData.match_start_at) }}’</span
                  >
                  }
                </div>
                <img
                  src="../../../template/green/assets/images/Live.png"
                  width="41"
                  height="16"
                />
              </div>
              <div>
                <img
                  [src]="bigMatchData.away_team_image"
                  width="92"
                  height="92"
                />
                <p class="big-match-team-name">
                  {{ bigMatchData.away_team_name }}
                </p>
              </div>
            </div>
            <button class="w-100 place-bet">BET NOW</button>
            <div
              class="d-flex justify-content-center align-items-center mt-1 big-match-list-logo"
            >
              <img
                src="../../../template/green/assets/images/SBOBET_Logo.png"
                width="66"
                height="16"
              />
              <img
                src="../../../template/green/assets/images/saba_sport.png"
                width="42"
                height="16"
              />
              <img
                src="../../../template/green/assets/images/w_entertainment.png"
                width="81"
                height="31"
              />
            </div>
          </div>
        </div>
        <div class="list-promotion">
          @if(promoJSONData.length > 0) { @for (item of promoJSONData; track
          item;) {
          <a
            [routerLink]="['/promotion']"
            [queryParams]="{ part: item._id }"
            class="w-100"
          >
            <img [src]="item.thumbnail_url" [alt]="item.title" class="w-100" />
          </a>
          }
          <a href="/promotion" class="w-100 see-all-promo">See All Promo</a>
          }
        </div>
      </div>
    }
  </div>
  @if(!hideLiveScore){
    <div class="home-middle-mobile d-md-none">
      <div class="big-match">
        <div class="big-match-header">BIG MATCH</div>
        <div class="latest-news">
          <div class="latest-news-left">
            <img
              src="../template/green/assets/images/ic-bullhorn.png"
              width="12"
              height="10"
            />
          </div>
          <div class="latest-news-right">
            <div class="latest-news-right-inner">
              <span class="newsticker-item"
                >Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a Latin
                professor at Hampden-Sydney College in Virginia, looked up one of
                the more obscure Latin words, consectetur, from a Lorem Ipsum
                passage, and going through the cites of the word in classical
                literature, discovered the undoubtable source. Lorem Ipsum comes
                from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
                BC. This book is a treatise on the theory of ethics, very popular
                during the Renaissance. The first line of Lorem Ipsum, "Lorem
                ipsum dolor sit amet..", comes from a line in section
                1.10.32.</span
              >
              <span class="marquee-sep"></span>
            </div>
          </div>
        </div>
        <div class="big-match-body">
          <p class="title">{{ bigMatchData.league_name }}</p>
          <div class="d-flex align-items-center justify-space-between">
            <div>
              <img
                src="../../../template/green/assets/images/FC_Barcelona.png"
                width="90"
                height="90"
              />
              <p class="big-match-team-name">Barcelona</p>
            </div>
            <div class="w-25 d-flex flex-column align-items-center mx-2">
              <span class="d-flex align-items-center">
                <p class="big-match-score">1</p>
                <p class="big-match-score mx-1">:</p>
                <p class="big-match-score">0</p>
              </span>
  
              <div class="d-flex align-items-center text-white my-2">
                <img
                  src="../template/green/assets/images/ic-timer.png"
                  width="8"
                  height="9"
                />
                <span class="min-live">33’</span>
              </div>
              <img
                src="../../../template/green/assets/images/Live.png"
                width="40"
                height="10"
              />
            </div>
            <div>
              <img
                src="../../../template/green/assets/images/Chelsea_FC.png"
                width="92"
                height="92"
              />
              <p class="big-match-team-name">Chelsea</p>
            </div>
          </div>
          <button class="w-100 place-bet">BET NOW</button>
          <div
            class="d-flex justify-content-center align-items-center mt-1 big-match-list-logo"
          >
            <img
              src="../../../template/green/assets/images/SBOBET_Logo.png"
              width="66"
              height="16"
            />
            <img
              src="../../../template/green/assets/images/saba_sport.png"
              width="42"
              height="16"
            />
            <img
              src="../../../template/green/assets/images/logo_M8BET.png"
              width="54"
              height="16"
            />
            <img
              src="../../../template/green/assets/images/w_entertainment.png"
              width="81"
              height="31"
            />
          </div>
        </div>
      </div>
      <app-jackpot [value]="5000000" />
      <div class="other-match-mobile">
        <div
          class="other-match-mobile-header d-flex align-items-center justify-content-between"
        >
          <p class="mb-0">Other Match</p>
          <button>
            12 Running Bets
            <img
              src="../../../template/green/assets/images/ic-caret-circle-right.png"
              width="10"
            />
          </button>
        </div>
        <div class="other-match-mobile-body">
          @for (item of otherMatchData; track item;) {
          <div>
            <div
              class="d-flex text-white align-items-center justify-content-between other-match-list-item-header"
            >
              <p>{{ item.title }}</p>
              <span class="d-flex">
                <p class="border-right border-white">
                  {{ item.dateTime | formatDateId }}
                </p>
                <p>
                  {{ item.dateTime | formatTimeId }}
                </p>
              </span>
            </div>
            <div class="other-match-list-item-body">
              <div class="d-flex">
                <div class="w-50 gap-1 teams">
                  <p>{{ item.team1 }}</p>
                  <p>{{ item.team2 }}</p>
                </div>
                <div class="w-25 gap-1 scores">
                  <p class="text-end">{{ item.score1 }}</p>
                  <p class="text-end">{{ item.score2 }}</p>
                </div>
                <div class="gap-1 info-live">
                  @if (item.live) {
                  <img
                    src="../../../template/green/assets/images/Live.png"
                    width="41"
                    height="16"
                    class="is-live pl-auto ml-auto"
                  />
                  <p class="min-live text-end">{{ item.minLive }}’</p>
                  } @if (!item.live) {
                  <p class="mb-0 info-date">
                    {{ item.dateTime | formatDateId }}
                  </p>
                  }
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-end mt-1">
                <p class="desc">{{ item.description }}</p>
  
                <div class="btn-place-bet">
                  <button class="place-bet">Place Bet</button>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <div class="other-match-mobile-footer">
          <button>SHOW ALL MATCHES</button>
        </div>
      </div>
  
      <div class="list-promotion">
        <app-slideshow-promotion [data]="listPromo" />
      </div>
    </div>
  }

  <app-games-widget></app-games-widget>

  <app-all-games-home />
  <app-footer [data]="footerData" />
  }
</div>
