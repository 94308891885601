<div>
  @if(currentScreenSize === 'xSmall'){
    @if (!provider) {
      <!-- <div class="d-flex d-md-none filter-container">
        <div class="filter-bar" (click)="openFilter()">
          <img src="../../template/green/assets/images/mobile/Filter.webp" class="img-fluid filter-icon" />
          <div class="filter-text">Filter</div>
        </div>
        <div class="container-filter-short">
          <div class="filter-short">
            <span>
              {{ selectedOptions.length === 0 ? "All Categories": selectedOptions.length === 1 ? gameTypesLabel :
              selectedOptions.length > 1 ? selectedOptions.length+" Categories" : "All Categories"}}
            </span>
            <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearCategory()"></fa-icon>
          </div>
          <div class="filter-short">
            <span>
              {{ tags.length === 0 ? "All Features": (tags.length === 1 && tags[0] !== '') ? (tags[0]).toLowerCase() : (tags.length === 1 && tags[0] === '') ? "All Features" : tags.length > 1 ? tags.length+" Features" : "All Features"}}
            </span>
            <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearTag()"></fa-icon>
          </div>
          <div class="filter-short">
            <span>
              {{ selectedOptionsProvider.length === 0 ? "All Providers": selectedOptionsProvider.length === 1 ? providersLabel :
              selectedOptionsProvider.length > 1 ? selectedOptionsProvider.length+" Providers" : "All Providers"}}
            </span>
            <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearProvider()"></fa-icon>
          </div>
          <div class="filter-short">
            {{sortByLabel}}
          </div>
        </div>
      </div> -->

      <div class="container-new-bar">
        <div class="title-provider">
          {{providersLabel}} {{gameTypesLabel}}
        </div>
        <div class="container-tags">
          <div class="tags">
            <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === '') ? 'active':''" (click)="setTag('')">Semua Permainan</div>
            <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'NEW') ? 'active':''" (click)="setTag('NEW')">New</div>
            
            @if (gameTypesLabel === 'Slots') {
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'BUYIN') ? 'active':''" (click)="setTag('BUYIN')">Buy-In</div>
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'JACKPOT') ? 'active':''" (click)="setTag('JACKPOT')">Jackpot</div>
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'BONANZA') ? 'active':''" (click)="setTag('BONANZA')">Bonanza</div>
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'MEGAWAYS') ? 'active':''" (click)="setTag('MEGAWAYS')">Megaways</div>
            }
            
            @if(gameTypesLabel === 'Live Casino'){
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'GAMESHOW') ? 'active':''" (click)="setTag('GAMESHOW')">Game Show</div>
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'BACCARAT') ? 'active':''" (click)="setTag('BACCARAT')">Baccarat</div>
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'BLACKJACK') ? 'active':''" (click)="setTag('BLACKJACK')">Blackjack</div>
              <div class="button-tag" [ngClass]="(tags.length === 1 && tags[0] === 'ROULETTE') ? 'active':''" (click)="setTag('ROULETTE')">Roulette</div>
            }
            

          </div>
        </div>
        <div class="search-games">
          <form class="w-100">
            <input type="text" class="form-control" placeholder="Cari Permainan" [(ngModel)]="searchGameInput"
              name="searchGameInput" (keyup.enter)="filterSearchChange($event)" aria-label="Cari Permainan" />
            <button type="button" class="btn">
              <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
            </button>
          </form>
        </div>
      </div>
    }
  }
  <div class="all-games" [ngClass]="{ 'min-height': minHeight === true }">
    <div>
      @if(filterAll === true) {
        @if(currentScreenSize !== 'xSmall'){
          <div class="d-flex align-items-start filter-new">
    
            <form class="w-100">
              <input
                type="text"
                class="form-control"
                placeholder="Search games.."
                [(ngModel)]="searchGameInput" name="searchGameInput" 
                (keyup.enter)="filterSearchChange($event)"
                aria-label="Search games.."
              />
              <button type="button" class="btn">
                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
              </button>
            </form>
            <div class="container-block">
              <div ngbDropdown class="position-relative filter-allgame" autoClose="outside">
                <button class="btn-dropdown px-4" ngbDropdownToggle>
                  <span>
                    {{ selectedOptions.length === 0 ? "All Games": selectedOptions.length === 1 ? gameTypesLabel :
                    selectedOptions.length > 1 ? selectedOptions.length+" Selected" : "All Games"}}
                  </span>
                  <!-- <fa-icon [icon]="faCaretDown" class="fa-md"></fa-icon> -->
                </button>
                <!-- <div> -->
                  <div ngbDropdownMenu class="dropdown-menu">
                    <div class="d-flex bg-white">
                      <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchGameInputCategories" name="searchGameInputCategories" aria-label="Search" (keyup)="onSearchDropdownValue($event)" />
                      <button type="button" class="btn">
                        <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                      </button>
                    </div>
                    <div class="d-flex flex-column gap-2 mt-3">
                      @for (option of searchedOptions; track option;) {
                      <label ngbDropdownItem for="{{option['code_small']}}" class="d-flex gap-2" [class.active]="option['checked']" (change)="onSelectDropdownValue($event)" >
                        <input id="{{option['code_small']}}" type="checkbox" [checked]="option['checked']" />
                        {{ option.title.en }}
                      </label>
                      }
                    </div>
                  </div>
                <!-- </div> -->
              </div>
      
              <div ngbDropdown class="position-relative filter-provider" autoClose="outside">
                <button class="btn-dropdown px-4"  ngbDropdownToggle>
                  <span>
                    {{ selectedOptionsProvider.length === 0 ? "All Providers": selectedOptionsProvider.length === 1 ? providersLabel :
                    selectedOptionsProvider.length > 1 ? selectedOptionsProvider.length+" Selected" : "All Providers"}}
                  </span>
                  <!-- <fa-icon [icon]="faCaretDown" class="fa-md"></fa-icon> -->
                </button>
      
                <!-- <div> -->
                  <div ngbDropdownMenu class="dropdown-menu">
                    <div class="d-flex bg-white">
                      <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchGameInputProvider" name="searchInput" aria-label="Search" (keyup)="onSearchDropdownValueProvider($event)" />
                      <button type="button" class="btn" >
                        <fa-icon [icon]="faMagnifyingGlass" type="submit"></fa-icon>
                      </button>
                    </div>
                    <div class="d-flex flex-column gap-2 mt-3">
                      @for (option of searchedOptionsProvider; track option;) {
                        <label ngbDropdownItem for="{{option['code']}}" class="d-flex gap-2" [class.active]="option['checked']" (change)="onSelectDropdownValueProvider($event)">
                          <input id="{{option['code']}}" type="checkbox"  [checked]="option['checked']" />
                          {{ option['name'] }}
                        </label>
                      }
                    </div>
                  </div>
                <!-- </div> -->
              </div>
              <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                <button class="btn-dropdown px-4" ngbDropdownToggle>
                  <span>{{ sortByLabel }}</span>
                </button>
      
                <div ngbDropdownMenu class="dropdown-menu">
                  <div class="d-flex flex-column gap-2">
                    <div ngbDropdownItem class="d-flex gap-2" [ngClass]="{'active': qParams['sort'] === 'popularity' || qParams['sort'] === undefined}" (click)="filterSortClick('popularity')" >
                      Popularity
                    </div>
                    <div ngbDropdownItem class="d-flex gap-2"  [ngClass]="{'active': qParams['sort'] === 'latest'}" (click)="filterSortClick('latest')" >
                      Latest
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }
      <div class="container-all-games">
        <div class="all-games-inner">
          @if(preload){ 
            @for (item of [].constructor(10); track item;) {
              <app-card-game [isLoading]="true" />
            } 
          } @else if(provider){
            @for (item of providerListsAll; track item.id;) {
              <app-card-game
                [info]="item"
                [image]="'../../../template/green/assets/images/providers/box/'+websiteJSONData.misc.provider_image+'/all/'+(item.name | slugName)+'.webp'"
                [imagesProvider]="item.images"
                [gacor]="item.gacor"
                [favourite]="item.favourite"
                [name]="item.name"
                [provider]="true"
                [percent]="item.rtp"
                [pattern]="item.pattern"
                [dc]="item.dc"
              />
            } 
           } @else {
            @for (item of data; track item.id;) {
              <app-card-game
                  [info]="item"
                  [image]="item.image"
                  [gacor]="item.gacor"
                  [favourite]="item.favourite"
                  [name]="item.title"
                  [providername]="item.provider_name"
                  [percent]="item.rtp"
                  [pattern]="item.pattern"
                  [dc]="item.dc"
                />
            }
           } 
          <div #scrollAnchor class="scroll-anchor"></div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</div>
