<div class="header-container">
  <div class="header-container-inner d-md-block" [ngClass]="{ 'd-none': pageTitle }">
    <div class="header-container-inner-wrapper">
        <div class="header-middle">
            @if(sourceAPK !== 'apk' && websiteJSONData.configuration.apk_url){
                <div class="banner-apk-top">
                    <span class="banner-background">MAIN LEBIH SERU DARI APLIKASI</span>
                    <!-- <img class="banner-apk" src="../../../template/green/assets/images/apk/mobile/background-download-app-header-mobile.webp"> -->
                    <a [href]="websiteJSONData.configuration.apk_url" class="banner-apk-download">
                        <img class="btn-image" src="../../../template/green/assets/images/apk/mobile/button-download-app-header-mobile.webp">
                    </a>
                </div>
            }
            <div class="header-middle-center">
            <a [routerLink]="['/']">
                <img [src]="websiteJSONData.header.logo" />
            </a>
            <!-- @if(dataHeaderGame){
                @if(dataHeaderGame.type === 'game'){
                    <a class="header-game" (click)="clickPlay(dataHeaderGame)">
                        <img [src]="dataHeaderGame.game_image" />
                    </a>
                } @else{
                    <a class="header-game" [routerLink]="['/landing']">
                        <img [src]="'../../../template/green/assets/images/apk/mobile/christmas.gif'" />
                    </a>
                }
            }@else{
                <a class="header-game" [routerLink]="['/event']">
                    <img [src]="'../../../template/green/assets/images/event_header.png'" />
                </a>
            } -->
            <div class="d-flex w-100 align-items-center justify-content-end">
                <!-- <form (keydown.enter)="searchItem()">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    [(ngModel)]="searchInput"
                    name="searchInput"
                    aria-label="Search"
                />
                <button type="button" class="btn" (click)="searchItem()">
                    <fa-icon [icon]="faMagnifyingGlass" type="submit"></fa-icon>
                </button>
                </form> -->

                @if(openMenu){
                    <button class="bttn-close" (click)="showMenu()">
                        <fa-icon [icon]="faXmark"></fa-icon>
                    </button>
                } @else {
                    <button class="btn-show" (click)="showMenu()">
                        <fa-icon [icon]="faBars"></fa-icon>
                    </button>
                }
            </div>
            </div>
            @if(isLoggedin){
                <div class="header-middle-right d-flex d-md-none">
                    <a href="#" class="w-100">
                        <button class="d-flex align-items-center justify-content-left gap-2 user-info">
                            <img src="../template/green/assets/images/ic-person.png" />
                            <p class="fw-bold pt-3">{{ username }}</p>
                        </button>
                    </a>
                    <a href="#" class="w-100">
                        <button class="d-flex align-items-center justify-content-left gap-2 user-info btn-balance w-100" disabled>
                            <img src="../template/green/assets/images/ic-card.png" />
                            @if(balance == undefined){
                            <p class="fw-bold pt-3">Loading...</p>
                            } @else {
                            <p class="fw-bold pt-3">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
                            }
                        </button>
                    </a>
                </div>
            }@else{
                <div class="header-middle-right">
                    <a [routerLink]="['/login']">
                        <button class="btn-login">MASUK</button>
                    </a>
                    <a [routerLink]="['/register']">
                        <button class="btn-register">DAFTAR</button>
                    </a>
                </div>
            }
        </div>
    </div>
    </div>
    @if(showNewsticker){
    <div class="menu-bottom">
        <div class="header-bottom-center">
            <div class="latest-news container-xxl pe-0">
                <div class="latest-news-left">
                    <img src="../template/green/assets/images/ic-bullhorn.png" width="12" height="10" />
                </div>
                <div class="latest-news-right">
                    <div class="latest-news-right-inner">
                        <app-newsticker [data]="newstickerData"></app-newsticker>
                    </div>
                </div>
                <!-- <a class="container-event-click" [routerLink]="['/event']">
                    <div class="event-button-container">
                        <img class="trophy" src="../template/green/assets/images/header/trophy-uno.gif" />
                        <img class="event" src="../template/green/assets/images/header/EVENT.png" />
                    </div>
                </a> -->
            </div>
        </div>
    </div>
    }
    @if(openMenu){
    <div class="hamburger-mobile" [ngClass]="[isLoggedin ? 'extra-padding':'', (sourceAPK !== 'apk' && websiteJSONData.configuration.apk_url) ? 'extra-top':'']">
        <div class="search">
            <form (keydown.enter)="searchItem()">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchInput"
                    name="searchValue" aria-label="Search" />
                <button type="button" class="btn" (click)="searchItem()">
                    <fa-icon [icon]="faMagnifyingGlass" type="submit"></fa-icon>
                </button>
            </form>
        </div>
        @if(isLoggedin){
        <div class="position-sticky top-menu">
            <div class="user-info">
                <img src="../../../template/green/assets/images/status-gold.png" class="img-status" />
                <div class="detail-info">
                    <div>
                        <p class="username">{{ username }}</p>
                        @if(balance == undefined){
                        <p class="balance">Loading...</p>
                        } @else {
                        <p class="balance">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
                        }
                    </div>
                    <img src="../../../template/green/assets/images/rank/{{(userGroup.name).toLowerCase()}}.webp" width="40px" height="32px" />
                </div>
                <div class="side-btn">
                    <a [routerLink]="['/profile']" [queryParams]="{'part': 'default'}">
                        <fa-icon [icon]="faEdit"></fa-icon>
                        <span class="m-0 p-0">EDIT PROFILE</span>
                    </a>
                </div>
            </div>
            <div class="submenu">
                <a [routerLink]="['/deposit']" class="menu-icon">
                    <fa-icon class="mb-2" [icon]="faWallet" [size]="'xl'"></fa-icon>
                    <span>Deposit </span>
                </a>
                <a [routerLink]="['/withdrawal']" class="menu-icon">
                    <fa-icon class="mb-2" [icon]="faMoneyBillTransfer" [size]="'xl'"></fa-icon>
                    <span>Withdraw</span>
                </a>
                <a [routerLink]="['/statement']" [queryParams]="{'part':'monthly'}" class="menu-icon">
                    <fa-icon class="mb-2" [icon]="faFileLines" [size]="'xl'"></fa-icon>
                    <span>Statement</span>
                </a>
                <a [routerLink]="['/transaction']" [queryParams]="{'part':'default'}" class="menu-icon">
                    <fa-icon class="mb-2" [icon]="faChartPie" [size]="'xl'"></fa-icon>
                    <span>Transaction</span>
                </a>
                <a [routerLink]="['/saldo']" class="menu-icon">
                    <fa-icon class="mb-2" [icon]="faSackDollar" [size]="'xl'"></fa-icon>
                    <span>Saldo</span>
                </a>
            </div>
        </div>
        } @else {
        <div class="auth-btn-container d-flex">
            <a [routerLink]="['/login']">
                <button class="btn-login">MASUK</button>
            </a>
            <a [routerLink]="['/register']">
                <button class="btn-register">DAFTAR</button>
            </a>
        </div>
        }
        <div class="position-relative d-flex flex-column language">
            <button (click)="openLang = !openLang">
                <div class="d-flex">
                    <fa-icon [icon]="faGlobe"></fa-icon>
                    <p class="m-0 p-0">Indonesia</p>
                </div>
                <fa-icon [icon]="faCaretDown"></fa-icon>
            </button>
            @if(openLang){
            <div class="lang-dropdown">
                <span (click)="chooseLang('Indonesia')">Indonesia</span>
                <!-- <span (click)="chooseLang('English')">English</span> -->
            </div>
            }
        </div>
        @if(sourceAPK !== 'apk' && websiteJSONData.configuration.apk_url){
        <!-- <div class="apk">
            <p>Akses Lebih Cepat & Mudah Download Aplikasi Kami Sekarang!</p>
            <a [href]="websiteJSONData.configuration.apk_url" class="btn-download">
                Download Now</a>
        </div> -->
        <div class="apk-img mt-2">
            <a [href]="websiteJSONData.configuration.apk_url" class="btn-download">
                <img src="../../../template/green/assets/images/img-apk-mobile.png" alt="apk" />
            </a>
        </div>
        }
        <div class="list-promotion">
            <app-slideshow-promotion [data]="promoJSONData" [type]="1" />
        </div>
        <div class="list-menu-middle">
            <div class="menu-card">
                <a [routerLink]="['/promotion']">
                    <p class="title">Promosi</p>
                    <p class="desc">Dapatkan promosi terbaik untuk kemenangan maksimal</p>
                    <img src="../../../template/green/assets/images/mobile-promosi.png" alt="Promosi" />
                </a>
            </div>
            @if(showReferral){
                <div class="menu-card">
                    <a [routerLink]="['/referral']">
                        <p class="title">Referral</p>
                        <p class="desc">Ajak temanmu dan dapatkan keuntungannya</p>
                        <img src="../../../template/green/assets/images/mobile-referral.png" alt="Referral" />
                    </a>
                </div>
            }
            <div class="menu-card">
                <a [routerLink]="['/event']">
                    <p class="title">Event</p>
                    <p class="desc">Lihat event terbaru dengan hadiah terbesar</p>
                    <img src="../../../template/green/assets/images/mobile-event.png" alt="Event" />
                </a>
            </div>
            <div class="menu-card">
                <a [routerLink]="['/contact']">
                    <p class="title">Contact</p>
                    <p class="desc">Hubungi kami untuk segala pertanyaan kamu</p>
                    <img src="../../../template/green/assets/images/mobile-contact.png" alt="Contact" />
                </a>
            </div>
        </div>
        <div class="list-menu-bottom">
            <a class="detail" [routerLink]="['/']">
                <p>Beranda</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
            <button class="detail" (click)="redirect('SL')">
                <p>Slot</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('LC')">
                <p>Live Casino</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('FH')">
                <p>Arcade</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('SB')">
                <p>Sportbook</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <!-- <button class="detail" (click)="redirect('LT')">
                <p>Togel</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button> -->
            <button class="detail" (click)="redirect('ES')">
                <p>Esports</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <a class="detail" [routerLink]="['/providers']">
                <p>Providers</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
            <button class="detail logout" [class.d-none]="!isLoggedin" (click)="logout()">
                <p>Sign Out</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
        </div>
        @if(isLoggedin){
            <a [routerLink]="['/deposit']" class="text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile" type="button">
                DEPOSIT
            </a>
        }
        
    </div>
    }
</div>